import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Plank 3×1:00`}</p>
    <p>{`Side Plank 3x:30/side`}</p>
    <p>{`DB Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`5 rounds for time:`}</p>
    <p>{`15-Right Arm KB Deadlifts (53/35)(Rx+70/53)`}</p>
    <p>{`100ft Right Arm Farmers Walk`}</p>
    <p>{`15-Left Arm KB Deadlifts`}</p>
    <p>{`100ft Left Arm Farmers Walk`}</p>
    <p>{`15-Right Arm Russian KB Swings`}</p>
    <p>{`100ft Right Arm Waiters Walk`}</p>
    <p>{`15-Left Arm Russian KB Swings`}</p>
    <p>{`100ft Left Arm Waiters Walk`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      